.container {
  width: 100%;
  height: 50vh;
  position: relative;
  background-color: #e9ecef;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6);
}

.container svg {
  display: block;
  width: 100%;
  height: 100%;
}

.interest {
  fill: #628cff;
}

.unknown {
  fill: #ff9401;
}

.node text {
  font: 12px sans-serif;
}

div.tooltip {
  position: absolute;
  text-align: center;
  width: 300px;
  padding: 10px;
  font: 12px sans-serif;
  background: #4f86fd;
  border: 0;
  border-radius: 8px;
  pointer-events: none;
}

.contextMenu {
  stroke: #00557d;
  fill: #ffffff;
}

.menuEntry {
  cursor: pointer;
}

.menuEntry text {
  font-size: 12px;
  stroke: #00557d;
}
