.request__section {
  padding: 100px 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fafafa;
}

.request__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 200px;
  padding: inherit;
  border-radius: 4px;
  background: #e63946;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
}

.report__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 200px;
  padding: inherit;
  border-radius: 4px;
  background: #0660E9;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.2);
}

.request__container {
  margin: 30px 30px;
}


.request__heading {
  color: #1c2237;
  margin-bottom: 20px;
}


.report__heading {
  color: #ececec;
  margin-bottom: 20px;
}

.request-input {
  padding: 10px 24px;
  border-radius: 2px;
  margin: 10px 20px 10px;
  outline: none;
  border: none;
  font-size: 20px;
  border: 1px solid #fff;
}

.request__btn {
  padding: 10px 24px;
  border-radius: 3px;
  margin: 10px 20px 10px;
  outline: none;
  border: none;
  font-size: 20px;
  background: #1c2237;
  color: #fff;
}


.request__btn:hover {
  padding: 10px 24px;
  border-radius: 3px;
  margin: 10px 20px 10px;
  outline: none;
  border: none;
  font-size: 20px;
  background: #fff;
  color: #1c2237;
}


.request__text {
  color: #1c2237;
  margin: 20px 50px 20px;
}


.report__text {
  color: #ececec;
  margin: 20px 50px 20px;
}




@media screen and (max-width: 960px) {
  .request__container {
    margin: 30px 30px;
  }

  .request__wrapper {
    margin: 30px 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

}
